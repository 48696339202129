// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {

    &:hover>.ps__thumb-y,
    &:focus>.ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }

  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
  &.ps--active-y>.ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;

    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }

  &.ps--scrolling-y>.ps__rail-y,
  &.ps--scrolling-x>.ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {

  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {

  0%,
  50%,
  100% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {

  0%,
  50%,
  100% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }
}

// Custom
.MuiTypography-root {
  text-transform: none;
}

header .MuiToolbar-root .MuiGrid-root .MuiList-root>.MuiListItemButton-root {
  display: inline-block;
  float: left;
  /* You can also add some margins here to make it look prettier */
}

.documents {
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @keyframes pulse {
    0% {
      background-color: rgba(165, 165, 165, 0.1)
    }

    50% {
      background-color: rgba(165, 165, 165, 0.3)
    }

    100% {
      background-color: rgba(165, 165, 165, 0.1)
    }
  }

  .customer-badge {
    border-radius: 2px;
    padding: .25em 0;
    // padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
  }

  .customer-badge.status-qualified {
    background-color: #C8E6C9;
    color: #256029;
  }

  .customer-badge.status-unqualified {
    background-color: #FFCDD2;
    color: #C63737;
  }

  .customer-badge.status-negotiation {
    background-color: #FEEDAF;
    color: #8A5340;
  }

  .customer-badge.status-new {
    background-color: #B3E5FC;
    color: #23547B;
  }

  .customer-badge.status-renewal {
    background-color: #ECCFFF;
    color: #694382;
  }

  .customer-badge.status-proposal {
    background-color: #FFD8B2;
    color: #805B36;
  }

  .product-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
  }

  .product-badge.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  .product-badge.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  .product-badge.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }

  .order-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
  }

  .order-badge.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  .order-badge.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  .order-badge.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  .order-badge.order-returned {
    background: #ECCFFF;
    color: #694382;
  }

  .image-text {
    vertical-align: middle;
    margin-left: .5rem;
  }

  .p-multiselect-representative-option {
    display: inline-block;
    vertical-align: middle;
  }

  .p-multiselect-representative-option img {
    vertical-align: middle;
    width: 24px;
  }

  .p-multiselect-representative-option span {
    margin-top: .125rem;
  }

  .p-column-filter {
    width: 100%;
  }

  .country-item {
    display: flex;
    align-items: center;
  }

  .country-item img.flag {
    width: 18px;
    margin-right: .5rem;
  }

  .flag {
    vertical-align: middle;
  }

  span.flag {
    width: 44px;
    height: 30px;
    display: inline-block;
  }

  img.flag {
    width: 30px
  }
}

.p-component {
  font-size: 0.875rem !important;
  font-family: 'Poppins' !important;
}

.react-tel-input .form-control {
  min-width: 300px;
  width: 100% !important;
}

.freeTokens .MuiPaper-root {
  border-radius: unset !important;
}

button {
  text-transform: none !important;
}

.MuiListItemIcon-root svg {
  max-width: 20px;
  // max-height: 20px;
}

.campaign-details ul {
  list-style: none;
}

.campaign-details ul li[data-list-indent="4"] {
  margin-left: 20px;
}

.campaign-details ul li[data-list-indent="3"] {
  font-weight: bold;
}

.guaranteeItem {
  position: relative;
  text-align: center;
}

.guaranteeImage {
  overflow: hidden;
  height: 200px;
}

.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: auto;
  z-index: 100;
  color: #fff;
  // cursor: pointer;
}

.video_arrow {
  width: auto;
  max-width: 100%;
}

.service-title {
  width: auto;
  max-width: 100%;
  border-radius: 8px;
  display: inline-block;
}

.Mui-selected {
  color: rgb(62, 191, 205);
}

a,
a:visited {
  color: rgb(62, 191, 205);
}

.PhoneInputInput input {
  padding-left: 0.4em;
  padding-right: 0.4em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border-radius: 0.2em;
  border: 1px solid #cfcfcf;
  background-color: #fcfcfc;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 20px;
}

.arrow-right {
  vertical-align: middle;
}

.MuiButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  color: inherit;
  text-transform: capitalize;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(33, 150, 243, 0.5);
  color: #2196f3;
  font-weight: 500;
  border-radius: 4px;
}

.newsfeed-test {
  background-color: white;
  font-family: 'Poppins';

  .image-list {
    display: grid;
    width: 750px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    padding-right: 25px;
    padding-left: 20px;
  }

  .image-list::-webkit-scrollbar {
    display: none;
  }

  .MuiImageListItem-root {
    display: flex;
    width: 362px;
  }

  .wide {
    width: 741px;
    grid-column-end: span 2 !important;
    grid-row-end: span 2 !important;
  }

  .advance-boxes {
    .lolly {
      padding-top: 20px;
      margin-top: 0;
    }

    h3 {
      color: #000;
      display: table-caption;
      line-height: 15px;
      font-size: 13px;
      margin-top: 0;
    }

    h2 {
      line-height: 33px;
      font-size: 22px;
      margin-bottom: 0;
      margin-top: 10px;
      font-weight: 700;
    }

    .boosted h2,
    .usd h2 {
      color: #aaa;
    }

    .earned h2 {
      color: #2BBFCC;
    }

    .validated h2 {
      color: #FFB800;
    }

    .MuiGrid-grid-xs-true {
      padding: 0;
    }

    .box {
      width: 741px;
      border-radius: 15px;
      line-height: 10px;
      padding-bottom: 6px;
      box-shadow: 0px 7px 23px 0px rgba(0, 0, 0, 0.05);
      background: linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%);
      border: 1.5px solid #FFFFFF;
    }
  }

  .MuiListSubheader-root {
    font-family: Poppins;
  }

  .sub-header {
    border-radius: 15px;
    border: 1.5px solid var(--white-100, #FFF);
    background: linear-gradient(113deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.80) 110.84%);
    box-shadow: 0px 7px 23px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(10.500000953674316px);
    margin-bottom: 20px;
  }

  .sub-header-title {
    color: black;
    font-size: 11px;
    font-weight: 500;
  }


  .image-list-item {
    border-radius: 15px;
    opacity: 0.9;
    height: 385px;
    width: 100%;
    display: flex;
    align-items: center;
    text-wrap: wrap;
  }

  .ImageListItem {}

  .MuiImageListItemBar-title,
  .MuiImageListItemBar-subtitle {
    text-wrap: wrap;
  }

  .MuiImageListItemBar-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    padding-bottom: 20px;
  }

  .MuiImageListItemBar-subtitle {
    font-size: 11px;
    line-height: 16.5px;
    margin-bottom: 50px;
  }

  .MuiImageListItemBar-titleWrap {
    padding: 22px;
  }

  .img-list-overlay {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 15px;
    background: #666666;
  }

  .image-title {
    background-color: rgba(255, 255, 255, 0);
    display: inline-block;
    font-family: 'Poppins';
  }

  .claim-button {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
    border-radius: 40px;
    background: var(--white-100, #FFF);
    color: #000;
    position: absolute;
    left: 22px;
    bottom: 22px;
  }

}

.proposal-container {
  width: 864px;

  .proposal-wrapper {
    // display: flex;
    height: 168px;
    justify-content: center;
    background: url("../images/screens/12.jpg") lightgray 50% / cover no-repeat;
    // padding-bottom: 20px;
    padding-top: 49px;
    // margin-bottom: 20px;
    font-family: Poppins;
    padding-left: 34px;

    .proposal-confirm-bar {
      width: 796px;
      border-radius: 15px;
      background: linear-gradient(112deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.50) 100%);
      box-shadow: 0px 7px 23px 0px rgba(0, 0, 0, 0.10);
      backdrop-filter: blur(20px);
      // width: 80%;
      color: black;
      padding: 0;
      margin: 0;


      .confirm-title {
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
      }

      .MuiCardContent-root {
        padding: 15px;
      }
    }

    .brand-name {
      font-size: 9px;
      font-weight: 400;
      line-height: 15px;
      display: inline-block;

    }

    .confirm-button {
      border-radius: 50px;
      background: #2BBFCC;
      color: white;
      width: 98px;
      height: 26px;
      font-family: Poppins;
      font-size: 9px;
      line-height: 13.5px;
      font-weight: 700;
    }

    .topbar-center {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  // .MuiGrid-root {
  //   background-color: white;
  // }

  // .proposal-card-wrapper {
  //   padding: 0;
  //   display: flex;
  //   flex-wrap: wrap;
  // }

  .proposal-card {
    font-family: Poppins;
    background-color: #F4F4F4;
    border-radius: 15px;
    color: black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    // margin: 10px;
    // display: flex;
    justify-content: center;
    // flex-direction: column;
    padding: 18px;
    margin: 0;

    aspect-ratio: 1 / 1;

    .card-title {
      font-size: 13px;
      font-weight: 400;
    }

    ol {
      padding-left: 18px;
      margin-left: 0;
    }

    ol li {
      // padding-bottom: 10px;
      font-size: 9px;
      line-height: 15px;
    }
  }

  .service-id-card {
    font-family: Poppins;
    background-color: #F4F4F4;
    border-radius: 15px;
    color: black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    padding: 15px;
    // display: flex;
    justify-content: center;
    margin-left: 20%;
    width: 148px;
  }

  .proposal-card ol li {
    line-height: 25px;
  }

  .customer-stats {
    padding-top: 40px;
  }

  .revenue-cards {
    // height: 157px;
    // width: 148px;
    width: 100%;
    border-radius: 15px;
    background: rgba(43, 190, 205, 0.20);
    color: black;
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: flex-end;
    line-height: 1px;
    padding-left: 15px;
    padding-bottom: 10px;
    aspect-ratio: 1 / 1;

    p {
      margin: 0.4em 0;
    }

    .revenue-cards-title {
      font-size: 10px;
      line-height: 15px;
      font-weight: 400;
      // margin-bottom: 0;
    }

    .revenue-cards-value {
      font-size: 14px;
      font-weight: 700;
      // margin-bottom: 0;
    }
  }

  .image-proposal {
    border-radius: 15px;
  }

  .token-images-wrapper {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;

    .token-image {
      margin: 0 10px 0 20px;
      border-radius: 15px;
    }
  }

  .token-video-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
    margin-left: 20px;

    .token-video {
      border-radius: 15px;
    }
  }

  .service-image-title {
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
  }

  .service-image-subtitle {
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-transform: capitalize;
  }

  .service-image-description {
    color: #000;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
  }

  .case-study-container {
    width: 80%;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins' !important;
  }

  .case-study-header {
    margin-bottom: 30px;
  }

  .case-study-logo {
    width: 80px;
    height: auto;
    margin-right: 10px;
    border-radius: 10px;
  }

  // .case-study {
  //   background-color: #F4F4F4;
  // }

  // .case-study-section {
  //   background-color: #F4F4F4;
  // }

  .case-study-person-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .case-study-person {
    display: flex;
    align-items: center;
    background-color: #F4F4F4;
  }
}

input:focus {
  outline: none;
  font-family: 'Poppins';
}

.box-search-bar {
  display: flex;
  align-items: center;
  margin-top: 0;
}

.MuiListItemText-primary {
  font-family: Inter !important;
}

.MuiListItemText-secondary {
  font-family: Inter !important;
}

.list-item-text {
  font-family: Inter !important;
}

.css-myeebc-MuiAvatar-root {
  color: #1C1C1C;
  background: #E3F5FF;
  border-radius: 8px;
}


.new-sidebar {
  .MuiImageListItemBar-titleWrap {
    width: 100%;
  }

  // all menu items, either parent or child + selected
  .MuiListItemButton-root {
    border-radius: 8px;
    padding: 4px 8px 4px 0px;
    margin-bottom: 4px;
  }

  .MuiListItemButton-root.Mui-selected {
    background: #F6F3AB;
  }

  .MuiListItemButton-root:hover {
    background: #F6F3AB;
  }

  .MuiListItemButton-root:hover {
    color: black !important;
  }

  // icons
  .MuiListItemIcon-root {
    padding-left: 8px;
    padding-right: 5px;
  }

  // newsfeed item aka link has just "<a>" tag, so will add margin, because of no chevron
  a.MuiListItemButton-root .MuiListItemIcon-root {
    margin-left: 26px;
  }

  .Mui-selected .MuiListItemIcon-root {
    color: black !important;
  }

  .MuiListItemText-root .MuiTypography-root {
    color: var(--black-100, #1C1C1C);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .icon-tabler-chevron-right,
  .icon-tabler-chevron-down {
    margin-left: 10px;
    color: var(--black-20, #1C1C1C33);
  }

}